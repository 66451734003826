@import "./assets/css/reset.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto+Slab:400,700|Roboto:400,700&display=swap&subset=cyrillic");
@import "~video-react/dist/video-react.css";

::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  display: none;
}
$Roboto: "Roboto", sans-serif;
$RobotoCondensed: "Roboto Condensed", sans-serif;
$RobotoSlab: "Roboto Slab", serif;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $RobotoCondensed;
  line-height: 1.4;
}

// React video default settings
.video-react {
  background: transparent;
  .video-react-video {
    outline-color: transparent;
    outline-width: 0;
  }
}

.loginLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
a {
  text-decoration: none;
}
.btnWrap {
  display: flex;
  justify-content: center;
}
.btn {
  border-radius: 26px !important;
  padding: 10px 20px !important;
  color: #fff !important;
  font-size: 18px !important;
  transition: 0.5s !important;
  &.red {
    background: #e57373 !important;
  }
  &.blue {
    background: #26c6da !important;
  }
}
.form {
  position: relative;
  width: 100%;
  max-width: 440px;
  .title {
    position: absolute;
    top: -40px;
    left: 50%;
    width: 100%;
    max-width: 350px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #e57373;
    border-radius: 25px;
    /* border-radius: 7px; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .formik {
    width: 100%;
    padding: 80px 20px 20px 20px;
    border-radius: 5px;
    background: #fff;
    .checkEmail {
      text-align: center;
      .checkEmail-close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #000;
        font-size: 1.5rem;
        cursor: pointer;
      }
      .checkEmail-title {
        color: black;
        font-size: 21px;
        font-weight: 700;
      }
      .checkEmail-line {
        display: block;
        max-width: 465px;
        height: 1px;
        background-color: #ed1c24;
        margin: 25px auto 30px;
      }
      .checkEmail-text {
        font-size: 15px;
        color: #2a2a2a;
        max-width: 495px;
      }
      .checkEmail-text-block {
        margin-bottom: 15px;
        .checkEmail-text {
          font-size: 15px;
          color: #2a2a2a;
          margin-bottom: 8px;
        }
        .checkEmail-text-li {
          padding: 0 35px;
          font-size: 15px;
          text-align: left;
          margin-bottom: 8px;
        }
      }
      .checkEmail-email {
        color: #c2185b;
        font-size: 25px;
        font-weight: 700;
      }
    }

    .inputWithIcon {
      display: flex;
      align-items: flex-end;
      svg {
        margin: 0 7px 5px 0;
      }
    }
    .forgot {
      font-size: 14px;
      margin: 10px 0 5px;
      cursor: pointer;
      text-align: center;
      text-decoration: underline;
      color: #006ce5;
    }
    .btn {
      font-size: 16px;
      color: #fff;
      // font-weight: 700;
      max-width: 150px;
      border-radius: 26px;
      padding: 10px 20px;
      text-transform: uppercase;
      background: linear-gradient(180deg, #1ee5b7 0%, #2168be 100%);
      // color: #ec407a;
      &:hover {
        opacity: 0.9;
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
    .instruction {
      font-size: 18px;
      text-align: center;
    }
    .terms {
      .MuiFormControl-root,
      .MuiTypography-body1 {
        width: 100%;
      }
      .span {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        text-transform: none;
        color: #006ce5;
        &:hover {
          background: none;
        }
      }
    }
  }
}
.dialogDefault {
  .MuiPaper-root {
    background: transparent !important;
    box-shadow: none !important;
  }
  .form {
    position: relative;
    max-width: 100%;
    overflow-y: hidden;
    .title {
      top: 25px;
      text-transform: none;
      z-index: 3;
    }
    .formik {
      margin-top: 50px;
      max-height: calc(100vh - 130px);
      overflow-y: scroll;
      height: 100%;
      text-align: center;
    }
  }
}

.default-btn {
  background: #00bcd4 !important;
  margin: 5px 0;
  border: none;
  border-radius: 20px !important;
  text-align: center;
  box-shadow: none !important;
  color: #fff !important;
  cursor: pointer;
  max-width: 510px;
  width: 100%;
  padding: 8px 15px;

  &:hover {
    box-shadow: none !important;
    background: #037b8a !important;
  }

  &_disabled {
    cursor: default;
    &:hover {
      background: #00bcd4 !important;
    }
  }
}

input[type="file"] {
  display: none;
}
